<template>
  <Page :title="'基层法律服务'">
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">基层法律服务</h1>
          <p class="desc">基层法律服务与律师的工作很相似，业务范围非常广泛，除不能办理刑事诉讼案件外，几乎可以涉足律师事务所的全部业务范围。律师代理、办理案件，依照法律规定可以收取费用；基层法律服务也依照法律规定可以收取费用，但不得因此盈利，具有公益性。
          </p>
          <div class="business-apply-button mt-5">
            <a-button class="mr-3"><router-link to="/basiclever" target="_blank">预约办理</router-link></a-button>
          </div>
          <span class="business-banner-head-icon flyz-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
        <div class="subtitle">
          <h2>基层法律服务指南</h2>
          <hr class="underline" />
        </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="业务范围">
            <div class="ov_text">
              <h4>基层法律服务所的业务范围</h4>
              <p>（一）担任法律顾问；</p>
              <p>（二）代理参加民事、经济、行政诉讼活动；</p>
              <p>（三）代理非诉讼法律事务；</p>
              <p>（四）主持调解纠纷；</p>
              <p>（五）解答法律咨询；</p>
              <p>（六）代写法律事务文书；</p>
              <p>（七）协助办理公证；</p>
              <p>（八）办理见证；</p>
              <p>（九）协助基层司法所开展法制宣传教育和其他有关业务工作。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="执业资格获取">
            <div class="ov_text">
              <div class="law_methods_main_text">
                <h4>基层法律服务工作者执业资格取得途径</h4>
                <p>取得基层法律服务工作者执业资格按《基层法律服务工作者管理办法》（司法部2000年第60号令）有关规定执行。</p>
                <p>1、通过全国基层法律服务工作者执业资格考试。考试由司法部统一组织，面向社会，定期举行。申请报考条件：</p>
                <p>（1）拥护宪法，遵守法律，有选举权和被选举权；</p>
                <p>（2）具有高中或者中等专业以上的学历；</p>
                <p>（3）品行良好；</p>
                <p>（4）身体健康。</p>
                <p>2、经考核合格。申请考核条件：</p>
                <p>（1）拥护宪法，遵守法律，有选举权和被选举权；</p>
                <p>（2）品行良好；</p>
                <p>（3）身体健康；</p>
                <p>（4）具有高等院校法学本科以上学历；或者具有大专以上学历，从事审判、检察业务，司法行政业务工作或者人大、政府法制工作已满5年。具备上述条件的人员，必须能够专职从事基层法律服务工作，可以申请按考核程序取得基层法律服务工作者执业资格。</p>
                <p>3、具备律师资格、公证员资格、企业法律顾问资格或者法律职业资格的人员，可以视为具备基层法律服务工作者执业资格。</p>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="执业证申领条件">
            <div class="ov_text">
              <h4>申领基层法律服务工作者执业证的条件</h4>
              <p>1、具有基层法律服务工作者执业资格或者律师资格、公证员资格、企业法律顾问资格、法律职业资格；</p>
              <p>2、在基层法律服务所实习满6个月，被该所鉴定合格；</p>
              <p>3、基层法律服务所决定聘用；</p>
              <p>4、申请执业登记时拥护宪法，遵守法律，有选举权和被选举权，品行良好，身体健康。</p>
              <p>申请执业登记前从事过律师、公证和企业法律顾问工作，审判、检察业务工作，司法行政业务工作和其他法律业务工作2年以上的，可以不经实习，直接申请执业登记。</p>
              <p>申请基层法律服务工作者执业登记的人员有下列情形之一的，不予进行执业登记：</p>
              <p>（1）受过刑事处罚的（过失犯罪的除外）；</p>
              <p>（2）被开除公职的；</p>
              <p>（3）无民事行为能力或者限制民事行为能力的；</p>
              <p>（4）曾被基层法律服务所开除处分的；</p>
              <p>（5）曾被吊销律师执业证书或者受到停止执业处罚期限未满的；</p>
              <p>（6）具有律师或者公证员资格，并已在律师事务所或者公证处执业的。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="form" tab="执业证申领程序">
            <div class="ov_text">
              <h4>申领基层法律服务工作者执业证的程序</h4>
              <p>1、申请基层法律服务工作者执业证应提交下列材料：</p>
              <p>（1）《基层法律服务工作者申请执业登记表》一式三份；</p>
              <p>（2）《基层法律服务工作者执业资格证书》或者律师、公证员、企业法律顾问、法律职业资格证书（复印件）；</p>
              <p>（3）身份证、学历证书（复印件）；</p>
              <p>（4）近期大一寸免冠正面相片4张；</p>
              <p>（5）基层法律服务所对申请人实习表现的鉴定意见；</p>
              <p>（6）基层法律服务所出具同意聘用申请人的证明；</p>
              <p>（7）健康状况证明；</p>
              <p>（8）登记机关要求提交的其他材料。</p>
              <p>2、申领基层法律服务工作者执业证的程序</p>
              <p>申请执业登记材料，由拟聘用申请人的基层法律服务所提交住所地市（区）司法局审查。住所地市（区）司法局自受理申请材料之日起15日内完成审查，并出具审查意见后报给地级市司法局。地级市司法局自收到申请材料之日起15日内完成审核，对符合条件的填写《基层法律服务工作者执业证汇总表》并制成软盘上报省司法厅办理执业登记。对准予执业登记的申请人，由省司法厅颁发《法律服务工作者执业证》。不准予执业登记的，应当说明理由。申请人对不准予执业登记决定如有异议，可以按照《行政复议法》和司法部有关规定申请行政复议。</p>
            </div>
          </a-tab-pane>
        </a-tabs>
        </div>
        <IntelligentConsult :value="BasicLeverUrl"></IntelligentConsult>
        <div class="mt-5 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="基层法律服务所">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  provider_name: providerQuery.provider_name,
                  provider_type: '基层法律服务所'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="基层法律服务工作者">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  person_type: '基层法律服务人员'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
        </div>
      </section>
    </article>
  </Page>
</template>

<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import IntelligentConsult from '@/components/business/IntelligentConsult'
export default {
  components: {
    Collection,
    Page,
    Org,
    City,
    Person,
    IntelligentConsult
  },
  data () {
    return {
      BasicLeverUrl: '/BasicLever',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: ''
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    }
  }
}
</script>
