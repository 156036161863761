<template>
  <div>
    <Collection name="org_professional" v-slot="{ collection }" :filter="{ parent: value }">
      <div class="screen_item" v-if="collection.length > 0">
          <h2 class="screen_title">业务范围:</h2>
          <p
            class="select_item"
            :class="{ active: professionalkey === '' }"
            @click="removePr('professional')"
          >
            不限
          </p>
          <p
            class="select_item"
            v-for="item in collection"
            :class="{ active: item.item_name === professionalkey }"
            :key="item.item_name"
            @click="
              selectPr(professionalkey, item.item_value, professionalname, item.item_name)
            "
          >
            {{ item.item_name }}
          </p>
      </div>
    </Collection>
    <div class="screen_item">
      <Collection name="city" v-slot="{ collection }">
        <p class="screen_title">所在市区:</p>
        <p
          class="select_item"
          :class="{ active: citykey === '' }"
          @click="remove('city')"
        >
          不限
        </p>
        <p
          class="select_item"
          v-for="item in collection"
          :class="{ active: item.district_id === citykey }"
          :key="item.district_name"
          @click="
            select(item.district_id, areakey, item.district_name, areaname)
          "
        >
          {{ item.district_name }}
        </p>
      </Collection>
    </div>
    <div class="screen_item">
      <Collection
        name="area"
        v-slot="{ collection }"
        :filter="{ parent: citykey }"
      >
        <p class="screen_title">所在县区:</p>
        <p
          class="select_item"
          :class="{ active: areakey === '' }"
          @click="remove('area')"
        >
          不限
        </p>
        <p
          class="select_item"
          v-for="item in collection"
          :class="{ active: item.district_id == areakey }"
          :key="item.district_name"
          @click="
            select(citykey, item.district_id, cityname, item.district_name)
          "
        >
          {{ item.district_name }}
        </p>
      </Collection>
    </div>
    <!-- <Collection name="org_professional" v-slot="{ collection }" :filter="{ parent: value }">
      <div class="screen_item" v-if="collection.length > 0">
          <h2 class="screen_title">业务范围:</h2>
          <p
            class="select_item"
            :class="{ active: professionalkey === '' }"
            @click="removePr('professional')"
          >
            不限
          </p>
          <p
            class="select_item"
            v-for="item in collection"
            :class="{ active: item.item_name === professionalkey }"
            :key="item.item_name"
            @click="
              selectPr(professionalkey, item.item_value, professionalname, item.item_name)
            "
          >
            {{ item.item_name }}
          </p>
      </div>
    </Collection> -->
    <div class="screen_item" v-show="cityname || areaname || professionalname">
      <p class="screen_title">已选条件:</p>
      <p class="select_item" v-show="cityname">
        {{ cityname }}
      </p>
      <p class="select_item" v-show="areaname">
        {{ areaname }}
      </p>
      <p class="select_item" v-show="professionalname">
        {{ professionalname }}
      </p>
    </div>
  </div>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'orgcity',
  components: {
    Collection
  },
  data () {
    return {
      citykey: '',
      areakey: '',
      professionalkey: '',
      cityname: '',
      areaname: '',
      professionalname: ''
    }
  },
  props: [
    'orgcity',
    'value'
  ],
  methods: {
    select (citykey, areakey, cityname, areaname) {
      this.citykey = citykey
      this.areakey = areakey
      this.cityname = cityname
      console.log(this.cityname)
      this.areaname = areaname
      console.log(this.areaname)
      this.$emit('change', { city: this.cityname, area: this.areaname })
    },

    remove (type) {
      if (type === 'city') {
        this.cityname = this.areaname = this.areakey = this.citykey = ''
      } else {
        this.areakey = this.areaname = ''
      }
      this.$emit('change', { city: this.cityname, area: this.areaname })
    },

    selectPr (professionalkey, professionalname) {
      this.professionalkey = professionalname
      this.professionalname = professionalname
      console.log(this.professionalname)
      this.$emit('change', { professional: this.professionalname })
    },

    removePr (type) {
      if (type === 'professional') {
        this.professionalname = this.professionalkey = ''
      }
      this.$emit('change', { professional: this.professionalname })
    }
  }
}
</script>
