<template>
  <div class="lawyer">
    <div class="local red" v-if="post.city != ''">{{ post.city }}</div>
    <div class="photo-box">
      <router-link :to="`/orgdetails/${post.provider_id}`"><img :src="getOrgPhotoURL(post.provider_id, post.cover)" /></router-link>
    </div>
    <p class="title">{{ post.provider_name }}</p>
    <p class="tel">联系电话：{{ post.phone }}</p>
    <p class="address">地址：{{ post.address }}</p>
    <div class="tag_box">
      <div class="ghost">
          <router-link :to="`/orgdetails/${post.provider_id}`">查看详细</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import base from '@/core/base.js'
export default {
  name: 'org',
  mixins: [base]
}
</script>
