<template>
  <div>
    <div class="lawyerUser">
      <div class="photo-box">
        <img :src="getPersonPhotoURL(post.person_id, post.face)">
      </div>
      <p class="title">姓名:{{ post.person_name }}</p>
      <p class="phone">电话:{{ post.phone }}</p>
      <p class="address">所属机构:{{ post.provider_name }}</p>
      <div class="tag_box">
        <div class="ghost">
            <router-link :to="`/persondetails/${post.person_id}`">查看详细</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import base from '@/core/base.js'
export default {
  name: 'person',
  mixins: [base]
}
</script>
